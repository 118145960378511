export default {
  items: [
    {
      name: 'API Tester',
      url: '/apitester',
      icon: 'icon-cloud-download',
      class: 'first-nav-link'
    },
    {
      name: 'API Performance',
      url: '/performance',
      icon: 'icon-speedometer',
      class: 'first-nav-link'
    },
    {
      name: 'Broker Tester',
      url: '/broker',
      icon: 'icon-envelope',
      class: 'first-nav-link'
    },
    {
      name: 'JWT Explorer',
      url: '/jwt',
      icon: 'icon-key',
      class: 'first-nav-link'
    },
    {
      name: 'Gateway Image',
      url: '/gateway-image',
      icon: 'icon-key',
      class: 'first-nav-link'
    },
    {
      name: 'Weather Upload',
      url: '/upload',
      icon: 'icon-cloud-upload',
      class: 'first-nav-link'
    },
    {
      name: 'DAQ Site Setup',
      url: '/daq-site-setup',
      icon: 'icon-globe',
      class: 'first-nav-link'
    },
    {
      name: 'BLT Upload',
      url: '/blt-upload',
      icon: 'icon-cloud-upload',
      class: 'first-nav-link'
    },
    {
      name: 'FAT Config',
      url: '/fat-config',
      icon: 'icon-cloud-upload',
      class: 'first-nav-link'
    }
  ]
};
