<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
        <i class="icon-user icons font-2xl"></i>
    </template>\
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item @click="logout"><i class="fa fa-lock" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';

export default {
  name: 'MSIHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$store.commit('keycloak/logout');
    }
  }
};
</script>
