<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/brand/msi_logo.svg" width="125" height="21" alt="MSI Logo">
        <img class="navbar-brand-minimized" src="img/brand/msi_sun_flat.svg" width="30" height="30" alt="MSI Logo">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/adminportal">Admin Portal</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <MSIHeaderDropdownAccnt/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <TheFooter>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a class="link-highlight" href="http://morgansolar.com">Morgan Solar Inc</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Footer as TheFooter
} from '@coreui/vue';
// eslint-disable-next-line import/extensions
import nav from '@/_nav';
import MSIHeaderDropdownAccnt from './MSIHeaderDropdownAccnt.vue';

export default {
  name: 'msicustom',
  components: {
    AppHeader,
    AppSidebar,
    TheFooter,
    MSIHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      nav: nav.items
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label);
    }
  }
};
</script>
